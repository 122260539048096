import axios from "axios";
import md5 from "crypto-js/md5";
import { ipcApiRoute, specialIpcRoute } from "@/api/main";
import { getFillAudioTime } from "@/utils/tools"
import { watch } from "less";

export const openLive = {
  data() {
    return {
      play: false,
      once: false,
      error: false,
      liveBreak: false,
      notify: null,
      inferVideo: "",
      inferAudio: "",
      waitVideo: "",
      liveIdx: 1,
      actIdx: 0, // 商品索引
      actAudioIdx: 0, // 商品音频索引
      filePath: "", //文件保存目录
      audioInfo: {}, // 声音相关配置信息
      actGoods: {}, // 商品信息
      baseGoodArr: [], // 商品列表
      goodArr: [], // 商品列表
      textArr: [], // 文案列表
      playList: [],
    };
  },
  methods: {
    devGoodsToText() {
      this.goodList.map((e) => {
        let obj1 = {
          id: e.id,
          zb_id: e.zb_id,
          order_id: e.order_id,
          card_id: e.card_id,
          slive_id: e.slive_id,
          list: [],
        };
        this.baseGoodArr.push(obj1);
        for (let i = 0; i < 5; i++) {
          let fileName = e.id + "_" + i + ".mp3";
          let obj = {
            fileName,
            path: this.filePath + "\\audio\\" + fileName,
          };
          this.audioSaveDone(obj);
        }
      });
    },
    // 批量获取
    goodsToText() {
      this.notify = this.$notify.info({
        title: "提示",
        message: "正在获取直播文案，请耐心等待",
      });
      this.goodList.map((e) => {
        let obj = {
          id: e.id,
          zb_id: e.zb_id,
          order_id: e.order_id,
          card_id: e.card_id,
          slive_id: e.slive_id,
          list: [],
        };
        this.baseGoodArr.push(obj);
        this.getGoodsTextList(e);
      });
      console.log("23123", this.baseGoodArr);
    },
    // 获取文案
    getGoodsTextList(params) {
      axios
        .post(
          "https://aip.baidubce.com/rpc/2.0/ai_custom/v1/wenxinworkshop/chat/completions?access_token=" + localStorage.getItem("access_token"), //v3.5
          JSON.stringify({
            messages: [
              {
                role: "user",
                content: `现在你是一位带货一哥，你需要根据我给出的商品信息撰写一段直播带货口播文案。你需要放大商品的亮点价值，激发购买欲。接下来是这款商品的信息：商品名称:${params.goods_name},商品价格：${params.goods_price},${params.goods_original ? "原价：" + params.goods_original : ""},${params.goods_text ? "商品描述:" + params.goods_text : ""
                  },${params.goods_merit ? "商品亮点：" + params.goods_merit : ""},${params.goods_norms ? "商品规格:" + params.goods_norms : ""},${params.goods_crowd ? "适用人群:" + params.goods_crowd : ""
                  },${params.goods_live_merit ? "优势：" + params.goods_live_merit : ""},${params.goods_live_ensure ? "保障：" + params.goods_live_ensure : ""
                  }。我希望生成一段放大商品的亮点价值，激发购买欲的直播商品纯文字介绍文案。生成五段相关联的文案每段200字左右，每段以/n/n结束，段落不需要添加提示，我需要根据/n/n在代码中进行分段处理`,
              },
            ],
            temperature: 0.95,
            top_p: 0.8,
            penalty_score: 1,
            disable_search: false,
            enable_citation: false,
            response_format: "text",
          })
        )
        .then((res) => {
          // console.log("获取的文案", res.data);
          if (res.data.result) {
            let txtArr = res.data.result.split("/n/n\n\n");
            txtArr.map((r, idx) => {
              if (r) {
                let data = {
                  fileName: params.id + "_" + idx + ".mp3",
                  text: r.replace(/\n|\/n/g, ''),
                  ...this.audioInfo,
                };
                this.AudioSynthesis(data);
              }
            });
            console.log('this.textArr', txtArr);
          } else {
            console.log("err:文案生成失败", res.data.error_msg);
            this.$message.error('文案生成失败')
          }
        })
        .catch((err) => {
          console.log("err:文案生成失败", err);
          this.$message.error('文案生成失败')
        });
    },
    // 合成音频
    async AudioSynthesis(data) {
      // console.log("合成参数", data);
      let params = {};
      if (data.code == 1) {
        // 阿里
        params = {
          appkey: data.appkey,
          token: data.token,
          text: data.text,
          format: "mp3",
          // voice 发音人，可选，默认是xiaoyun。
          voice: data.voice,
          // volume 音量，范围是0~100，可选，默认50。
          volume: data.volume,
          // speech_rate 语速，范围是-500~500，可选，默认是0。
          speech_rate: data.speech_rate,
          // pitch_rate 语调，范围是-500~500，可选，默认是0。
          pitch_rate: data.pitch_rate,
        };

        fetch("https://nls-gateway-cn-shanghai.aliyuncs.com/stream/v1/tts", {
          method: "POST",
          headers: {
            "X-NLS-Token": data.access_key_secret,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(params),
        })
          .then((response) => {
            console.log("res", response);
            if (!response.ok) {
              throw new Error("Network response was not ok");
            }
            response.blob().then((audioBlob) => {
              let obj = {
                downloadBlob: audioBlob,
                fileName: data.fileName,
                type: data.type,
                insertType: data.insertType,
                platform: 'ali'
              }
              this.download(obj);
            });
          });
      } else if (data.code == 2) {
        // 序列猴子
        const toBeHashed = `${data.access_key_id}+${data.access_key_secret}+${Math.floor(new Date().getTime() / 1000)}`;
        const encrypted = md5(toBeHashed).toString();
        params = {
          signature: encrypted,
          timestamp: Math.floor(new Date().getTime() / 1000),
          appkey: data.access_key_id,
          speaker: data.voice,
          text: data.text,
          speed: data.speed,
        };
        fetch("https://open.mobvoi.com/api/tts/v1", {
          method: "POST",
          headers: {
            // "Content-Type": "application/json",
            "Content-Type": "audio/mpeg",
          },
          body: JSON.stringify(params),
        }).then((response) => {
          console.log("res", response);
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          response.blob().then((audioBlob) => {
            let obj = {
              downloadBlob: audioBlob,
              fileName: data.fileName,
              type: data.type,
              insertType: data.insertType,
              platform: 'xulie'
            }
            this.download(obj);
          });
        });
      } else {
        // 火山引擎
        params = {
          app: {
            appid: data.access_key_id,
            token: "access_token",
            cluster: data.voice.substr(0, 2) != 'BV' ? "volcano_mega" : 'volcano_tts',
          },
          user: {
            uid: localStorage.getItem('token'),
          },
          audio: {
            voice_type: data.voice,
            encoding: "mp3",
            compression_rate: 1,
            rate: 24000,
            speed_ratio: 1.0,
            volume_ratio: 1.0,
            pitch_ratio: 1.0,
            emotion: "happy",
            language: "cn",
          },
          request: {
            reqid: localStorage.getItem('token'),
            text: data.text,
            text_type: "plain",
            operation: "query",
            silence_duration: "125",
            with_frontend: "1",
            frontend_type: "unitTson",
            pure_english_opt: "1",
          },
        };
        fetch("https://openspeech.bytedance.com/api/v1/tts", {
          method: "POST",
          headers: {
            Authorization: `Bearer;${data.token}`,
          },
          body: JSON.stringify(params),
        })
          .then((response) => response.json())
          .then((res) => {
            const base64Data = res.data;
            const binaryString = atob(base64Data);
            const binaryLen = binaryString.length;
            const bytes = new Uint8Array(binaryLen);
            for (let i = 0; i < binaryLen; i++) {
              bytes[i] = binaryString.charCodeAt(i);
            }

            this.$ipc.invoke(ipcApiRoute.downloadAudio, {
              url: JSON.stringify(res.data),
              filePath: this.filePath,
              fileName: data.fileName,
              time_period: this.liveInfo.time_period,
              // duration: res.addition.duration / 1000,
              type: data.type || "goods",
              insertType: data.type == 'back' ? data.insertType : 'normal',
              platform: "huoshan",
            });
          });
      }
    },
    // 下载
    async download(args) {
      let { downloadBlob, fileName, type, insertType, platform } = args
      const reader = new FileReader();
      reader.onloadend = async () => {
        const base64String = reader.result.split(",")[1];
        // 通过 IPC 调用主进程保存文件
        try {
          this.$ipc.invoke(ipcApiRoute.downloadAudio, {
            url: base64String,
            filePath: this.filePath,
            time_period: this.liveInfo.time_period || [],
            platform,
            type: type || "goods",
            insertType: type == 'back' ? insertType : 'normal',
            fileName,
          });
        } catch (error) {
          console.error("Error saving file:", error);
        }
      };
      reader.readAsDataURL(downloadBlob);
    },
    // 缓存
    audioSaveDone(e) {
      let insertType = e.insertType || this.option.insertType
      if (e.type == "back") {
        console.log(e);

        if (insertType == 'now') {
          console.log('打断回复', e);
          // 打断推流
          this.inferBreak(e);

        } else {
          this.actGoods.list.splice(this.actAudioIdx + 1, 0, e.path)
        }
      } else {
        const regex = /\\([^\\]+)_(\d+)\.mp3$/;
        const arr = e.path.match(regex) || null;
        if (arr && arr.length >= 2) {
          const item = this.baseGoodArr.find((r) => arr[1] * 1 == r.id);
          if (item) item.list[arr[2] * 1] = e.path;
        }
        this.checkAudio()
      }
    },
    checkAudio() {
      if (this.baseGoodArr.length >= 3) {
        let list1 = this.baseGoodArr[0]?.list.length >= 5;
        let list2 = this.baseGoodArr[1]?.list.length >= 5;
        let list3 = this.baseGoodArr[2]?.list.length >= 5;
        let openLive = list1 && list2 && list3 && !this.once && this.inferVideo;
        console.log(111, list1, list2, list3);
        console.log('openLive', openLive, this.once, this.inferVideo);
        if (openLive) {
          console.log("可以开播", this.baseGoodArr);
          this.notify.close();
          this.notify = this.$notify.success({
            title: "提示",
            duration: 0,
            message: "正在准备开播，请耐心等待",
          });

          this.goodArr = JSON.parse(JSON.stringify(this.baseGoodArr));
          this.once = true;
          this.actIdx = 0;
          this.actAudioIdx = 0;
          this.actGoods = this.goodArr[this.actIdx];
          console.log(this.actGoods);

          this.submitInference();
        }
      }
    },
    // 提交推理
    submitInference(count = 0) {
      console.log("提交推理", count, this.actGoods, this.actAudioIdx);
      let name = `${this.actGoods.id}_${this.actAudioIdx}.mp3`;
      this.submitResource(this.actGoods.list[this.actAudioIdx], name, "upload_audio");
    },
    // 资源上传
    submitResource(filePath, fileName, route) {
      fetch(filePath)
        .then((response) => response.blob())
        .then(async (blob) => {
          const file = new File([blob], fileName, {
            type: 'audio/mpeg',
          });
          console.log(111, filePath, file);

          const formData = new FormData();
          formData.append("file", file);
          const url = "http://127.0.0.1:5100/" + route;
          const resp = await fetch(url, {
            method: "POST",
            body: formData,
          });
          const r = await resp.json();
          console.log("服务器响应：", r);
          this.upResult(r, route);
        })
        .catch((error) => {
          console.error("Error fetching the audio file:", error);
        });
    },
    // 上传结果处理
    upResult(e, type) {
      if (e.code == 200) {
        switch (type) {
          case "upload_source":
            this.loading.close()
            this.inferVideo = e.data.name;
            this.webrtcPath = e.data.player_url;
            this.initSrs();
            this.checkAudio()
            break;
          case "upload_audio":
            console.log("开始推理");
            this.inferAudio = e.data;
            this.inferPlay();
            break;
        }
      } else {
        console.log("资源上传失败");
        if (type == 'upload_source') this.error = true
      }
    },
    // 推理播放
    async inferPlay(count = 0) {
      console.log('播放资源', this.inferVideo, this.inferAudio);
      if (this.inferVideo) {
        const resp = await fetch('http://127.0.0.1:5100/inference_start', {
          method: "POST",
          body: JSON.stringify({ video_name: this.inferVideo, audio_name: this.inferAudio }),
          headers: {
            'content-type': 'application/json'
          }
        })
        const r = await resp.json()
        console.log('响应：', r)
        this.handleNotification();
      }
    },
    // 播放完成，再次推理
    handleNotification() {
      if (this.liveBreak) {
        this.liveBreak = false;
        return;
      }
      console.log(1111, "播放完成，再次推理", this.actAudioIdx);
      this.actAudioIdx++;
      if (this.actAudioIdx >= this.actGoods.list.length) {
        this.actAudioIdx = 0;
        this.actIdx++;

        if (this.actIdx >= this.goodArr.length) {
          this.goodArr = [];
          this.goodArr.push(...JSON.parse(JSON.stringify(this.baseGoodArr)));
          this.actIdx = 0;
          this.liveIdx++;
        }
        this.actGoods = this.goodArr[this.actIdx];
      }
      const islastGood = this.actIdx == this.goodArr.length - 2;
      const getNew = this.liveIdx % this.setNum == 0 && islastGood && this.actAudioIdx == 1;
      console.log("liveIdx", this.liveIdx);
      if (getNew) {
        console.log("**************播放完成，重新获取***************", new Date());
        this.baseGoodArr = [];
        this.goodsToText();
      }
      this.submitInference();
    },
    // 打断推流
    async inferBreak(e) {
      this.liveBreak = true;
      const resp = await fetch("http://127.0.0.1:5100/inference_break", {
        method: "POST",
      });
      const r = await resp.json();
      console.log("打断响应：", r);
      setTimeout(() => {
        this.submitResource(e.path, e.fileName, "upload_audio");
        this.actAudioIdx--;
      }, 3000);
    },
    watchIpcSendMsg() {
      this.$ipc.removeAllListeners(ipcApiRoute.ipcSendMsg);
      this.$ipc.on(ipcApiRoute.ipcSendMsg, (event, res) => {
        // console.log("ipcApiRoute.ipcSendMsg:", res);
        switch (res.msg) {
          case "serverStatus":
            this.submitResource(this.waitVideoPath, "upload_source");
            break;
          case "audioSaveDone":
            this.audioSaveDone(res);
            break;
          case "notification":
            if (res.type == "next") {
              // this.handleNotification();
            }
            break;
          case "getDuration":
            this.getDuration(res)
            break;
          default:
            break;
        }
      });
    },
  },
  created() {
    this.audioInfo = JSON.parse(localStorage.getItem("liveInfo"))?.sound;
    this.filePath = localStorage.getItem("basePath");
    this.watchIpcSendMsg();
  },
};
