<template>
  <div>
    <div class="con">
      <div class="box left p20">
        <div class="df jc-sb mb15">
          <div class="title">商品列表</div>

          <div class="fitem">
            <el-radio-group v-model="option.israndom" size="mini" @input="changeIsRandom">
              <el-radio-button :disabled="isOpenLive" :label="1">顺序播放</el-radio-button>
              <el-radio-button :disabled="isOpenLive" :label="2">随机播放</el-radio-button>
            </el-radio-group>
          </div>
        </div>
        <div class="f-w list">
          <div class="df f-w jc-sb" v-if="goodList.length > 0">
            <div v-for="item in goodList" :key="item.id" class="goodItem df" @click="getItem(item)">
              <img :src="item.logo" object-fit="cover" alt="" />
              <div class="info">
                <div class="tit">{{ item.goods_name }}</div>
                <div class="mt5">{{ item.create_time }}</div>
                <div class="mt5">卡片id: {{ item.card_id }}</div>
              </div>
            </div>
          </div>
          <div v-else>
            <myempty text="商品"></myempty>
          </div>
        </div>
      </div>

      <div class="box center">
        <img v-show="!webrtcPath" :src="liveInfo.image_url" alt="" />
        <iframe v-show="webrtcPath" ref="myFrame" :src="src" frameborder="0"></iframe>
        <div class="bottom"><span class="mr15">音量：</span><el-slider v-model="volume" :max="10" @change="changeVolume"></el-slider></div>
      </div>

      <div class="right">
        <div class="top df" style="border-radius: 10px 10px 0 0">
          <div class="cp" :class="isact == 1 ? 'act' : ''" @click="isact = 1">开启直播</div>
          <div class="cp" :class="isact == 2 ? 'act' : ''" @click="isact = 2">弹幕列表</div>
        </div>

        <div v-show="isact == 1" class="box1 box2" style="border-radius: 0 0 10px 10px">
          <div class="form">
            <div>
              <div class="tit">互动回复逻辑</div>
              <div class="fitem">
                <el-radio v-model="option.insertType" label="interval">讲解后互动</el-radio>
                <el-radio v-model="option.insertType" label="now">打断讲解</el-radio>
              </div>
            </div>
            <div class="tit">间隔&emsp;<el-input style="width: 30%" v-model="option.interaction" type="number"></el-input>&emsp;秒进行互动回复</div>
            <div class="tit">间隔&emsp;<el-input style="width: 30%" v-model="option.control" type="number"></el-input>&emsp;秒进行场控互动</div>
            <div class="tit" v-show="liveInfo.is_lead">间隔&emsp;<el-input style="width: 30%" v-model="option.time" type="number"></el-input>&emsp;分进行定时引导</div>
            <div class="tit">商品播放到第&emsp;<el-input style="width: 16%" v-model="setNum" type="number"></el-input>&emsp;轮的时候进行改写文案</div>
            <div class="tit dfc mt10" v-show="!isOpenLive">
              <el-button type="success" @click="openLive">开启直播</el-button>
            </div>

            <!-- <div class="tit dfc">
              <el-button @click="checkNum1">获取音频</el-button>
              <el-button @click="submit(1)">等待视频</el-button>
              <el-button @click="submit(2)">推理视频</el-button>
              <el-button @click="submit(3)">音频内容</el-button>
            </div> -->

            <div  v-show="isOpenLive">
              <div class="tit">弹幕配置</div>

              <div class="tit">
                <el-select v-model="form.platform" clearable placeholder="请选择直播平台" @change="selChange">
                  <el-option v-for="i in $store.state.sortList" :label="i.label" :value="i.value" :key="i.value"></el-option>
                </el-select>
              </div>

              <div class="tit" v-show="showAddr">
                <el-input style="width: 83%" v-model="form.input" placeholder="请输入直播间地址"></el-input>
              </div>

              <div v-show="form.platform == 'dy' || form.platform == 'ks' || form.platform == 'pdd'">
                <div class="tit">商品卡片</div>
                <div class="fitem">
                  <el-radio-group v-show="form.platform == 'dy' || form.platform == 'ks'" v-model="option.showProduct">
                    <el-radio :label="0">不开启</el-radio>
                    <el-radio :label="1">小黄车</el-radio>
                    <el-radio v-show="form.platform != 'ks'" :label="2">团购</el-radio>
                  </el-radio-group>
                  <el-radio-group v-show="form.platform == 'pdd'" v-model="option.showProduct">
                    <el-radio :label="0">不开启</el-radio>
                    <el-radio :label="1">开启</el-radio>
                  </el-radio-group>
                </div>
              </div>
              <div class="cp ml15 mt10" style="color: #3370ff; text-align: left" v-show="option.showProduct != 0" @click="openWin">
                点击登录直播账号 &nbsp;<i class="el-icon-arrow-right"></i>
              </div>
              <!-- <div @click="showCard">点击卡片</div> -->
              <div class="tit dfc">
                <el-button type="warning" @click="openWs">获取弹幕</el-button>
                <el-button type="danger" @click="closeLive()">关闭直播</el-button>
              </div>
            </div>
          </div>
        </div>

        <div v-show="isact == 2" class="">
          <div class="box1 reviewBox">
            <div id="listBox" v-if="reviewList.length">
              <div class="litem dfc" v-for="(i, idx) in reviewList" :key="idx">
                <div class="main">{{ i.name }}: {{ i.content }}</div>
              </div>
            </div>
            <div v-else style="margin: 0 auto">
              <myempty text="弹幕"></myempty>
            </div>
          </div>

          <div class="box1 recoverBox">
            <div class="title dfc">
              <span>实时回复</span>
            </div>
            <div class="mt10 mb10">
              <el-input type="textarea" :rows="10" resize="none" placeholder="请输入内容" maxlength="300" show-word-limit v-model="recoverText" clearable> </el-input>
            </div>
            <div style="text-align: right">
              <el-button type="primary" @click="sendText">发送</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import myempty from "@/components/myempty.vue";
import { get_goods_list, check_captions, local_doc_chats } from "@/api/slive.js";
import { shuffle, formatDate, randomText6 } from "@/utils/tools";
import { ipcApiRoute, specialIpcRoute } from "@/api/main";
import { openLive } from "./js/live.js";
import axios from "axios";
export default {
  name: "third",
  components: {
    myempty,
  },
  mixins: [openLive],
  props: {
    liveId: {
      type: Number,
      default: 0,
    },
    liveInfo: {
      type: Object,
      default: () => {},
    },
    waitVideoPath: {
      type: String,
      default: "",
    },
    inferVideoName: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      form: {
        input: "",
        platform: "",
      },
      src: "static/video.html",
      volume: 1,
      index: 0,
      isact: 1,
      total: 1,
      page: 1,
      setNum: 1,
      recoverText: "",
      /* recoverText:
        "大家好，欢迎来到我们的直播间！今晚，我手里这份特价牛肉火锅套餐，绝对是冬日里的温暖神器，原价297元，现在只需197元，超值享受不容错过！", */
      webrtcPath: "",
      allItemNum: "", // 商品总数
      showAddr: false,
      srsplay: false,
      monitor: true,
      interaction: true,
      srsSdk: null,
      serverStatus: false,
      isOpenLive: false,
      loading: null,
      option: {
        israndom: 1,
        name: "",
        insertType: "interval",
        showProduct: 0,
        time: "",
        interaction: 1,
        control: 1,
      },
      activeInfo: {},
      goodList: [],
      baseGoodList: [],
      reviewList: [],
    };
  },
  watch: {
    baseGoodList(n) {
      // if (this.baseGoodList.length == this.allItemNum) {
      //   this.checkNum();
      // }
    },
  },
  mounted() {
    this.getlist();
    // this.getRecover("柠檬水多少钱一杯");
  },
  methods: {
    openLive() {
      /*
      1.获取文案->合成音频->缓存音频
      2.检测服务->检测本地文件
      3.开始合成
      */
      if (this.goodList.length < 3) {
        this.$message.error("请添加三条或三条以上商品开播");
        this.closeLive();
        return;
      }

      this.loading = this.$loading({
        lock: true,
        text: "正在加载画面中",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.$emit("changeStatus");
      this.isOpenLive = true;
      this.$store.commit("saveOpenlive", true);
      this.checkNum();
      console.log("this.inferVideoName", this.inferVideoName);
      this.submitResource(this.liveInfo.url, this.liveInfo.image_id + "_infer.mp4", "upload_source");
    },
    sendToFrame(data) {
      const iframe = this.$refs.myFrame;
      iframe.contentWindow.postMessage(data, "*");
    },
    submit(type) {
      switch (type) {
        case 1:
          this.submitResource("D:\\work\\Desktop\\9sVideo.mp4", "9sVideo.mp4", "upload_wait_video");
          break;
        case 2:
          this.submitResource("D:\\work\\Desktop\\9sYSP.mp4", "9sYSP.mp4", "upload_source");
          break;
        default:
          this.submitResource("D:\\work\\Desktop\\9s.mp3", "9s.mp3", "upload_audio");
          break;
      }
    },
    // 检测商品数量
    checkNum(timeout = 5000, startTime = Date.now(), attempts = 0) {
      const currentLength = this.baseGoodList.length;
      const totalItems = this.allItemNum;

      if (currentLength == totalItems) {
        console.log(`获取到所有商品: ${totalItems} 个`);
        // 执行
        this.goodsToText(this.goodList);
        // this.devGoodsToText(this.goodList);
      } else if (Date.now() - startTime < timeout) {
        // 如果还未超过超时时间，则继续尝试
        setTimeout(() => {
          this.checkNum(timeout, startTime, attempts + 1);
        }, 1000);
      } else {
        // 超时后停止递归调用
        console.error(`超时：未能在 ${timeout / 1000} 秒内获取到所有 ${totalItems} 个商品。尝试了 ${attempts} 次.`);
        this.$message.error("商品数据获取失败，请联系管理员");
      }
    },
    checkNum1() {
      let obj = {
        fileName: "9.mp3",
        text: "超值抢购！原价120，现仅需88元，就能把这道星级大厨手艺带回家——【软嫩可口，肥而不腻】的红烧肉！精选上等五花肉，慢火细炖，每一口都是对味蕾的极致宠溺。",
        ...this.audioInfo,
      };
      this.AudioSynthesis(obj);
    },
    getlist(isFir = false) {
      const loading = this.$loading({
        lock: true,
        text: "获取数据中",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      if (isFir) {
        this.goodList = [];
        this.baseGoodList = [];
        this.page = 1;
      }
      get_goods_list({
        page: this.page,
        item: 10,
        slive_id: this.liveId,
      }).then((res) => {
        loading.close();
        if (res.code == "200") {
          this.goodList.push(...res.data.list);
          this.baseGoodList.push(...res.data.list);
          this.pageNum = res.data.page_num;
          this.allItemNum = res.data.count;
          if (this.pageNum > this.page) this.getlist();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    initSrs() {
      let data = {
        type: "play",
      };
      this.sendToFrame(data);
    },
    sendText() {
      if (!this.recoverText) {
        this.$message.error("请输入内容");
        return;
      }
      let data = {
        fileName: randomText6() + ".mp3",
        text: this.recoverText,
        type: "back",
        insertType: "now",
        ...this.liveInfo.sound,
      };
      this.AudioSynthesis(data);
    },
    closeLive() {
      this.$confirm("确认关闭,确认将关闭直播？")
        .then((_) => {
          this.$emit("changeStatus");
          this.isOpenLive = false;
          // this.$ipc.invoke(ipcApiRoute.openServer, "close.exe");
          // setTimeout(() => {
          //   this.$ipc.invoke(ipcApiRoute.openServer, "srs-stop.exe");
          // }, 3000);
          this.act = 1;
          this.form.platform = "";
          this.form.input = "";
          this.showAddr = false;
          this.once = false;
          this.webrtcPath = "";
          this.inferBreak();
          this.$store.commit("saveOpenlive", false);
          this.$ipc.invoke(ipcApiRoute.closeSoftware, this.appName);
          // this.$ipc.invoke(ipcApiRoute.closeCardWin);
          this.socket1.close();
        })
        .catch((_) => {});
    },
    changeIsRandom(e) {
      if (e == 2) {
        this.goodList = shuffle(this.goodList);
      } else {
        this.goodList = JSON.parse(JSON.stringify(this.baseGoodList));
      }
    },
    openWin() {
      // 显示抖音直播后台
      this.$ipc.invoke(ipcApiRoute.loadUrl, {
        platform: this.form.platform,
        type: this.option.showProduct,
      });
    },
    openWs() {
      if (!this.form.platform) {
        this.$message.error("请选择直播间类型！");
        return;
      }
      if (this.chooseRoom == "qita") {
        return;
      }
      if (!this.form.input) {
        this.$message.error("请输入直播间地址!");
        return;
      }

      const loading = this.$loading({
        lock: true,
        text: "正在开启监听",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let path = "";
      const matchedItem = this.$store.state.sortList.filter((item) => item.value === this.form.platform)[0];
      console.log("matchedItem", matchedItem);

      this.appName = matchedItem.appName;
      path = matchedItem.path;
      this.$ipc.invoke(ipcApiRoute.closeSoftware, 'app.exe');
      this.$ipc.invoke(ipcApiRoute.closeSoftware, 'main.exe');

      setTimeout(() => {
        let data = {
          path,
          addr: this.form.input,
          platform: matchedItem.value,
          appName: matchedItem.appName,
        };
        this.$ipc.invoke(ipcApiRoute.editLocalIniFile, data).then((r) => {
          loading.close();
          this.$ipc
            .invoke(ipcApiRoute.openSoft, {
              softName: matchedItem.appName,
              path: matchedItem.catalogue,
            })
            .then((r1) => {
              console.log("resourcePath:", r1);
              if (r1) this.startWsServer();
            });
        });
      }, 300);
    },
    startWsServer() {
      this.$message.success("正在启动弹幕获取程序，请耐心等待");
      setTimeout(() => {
        this.socket1 = new WebSocket("ws://127.0.0.1:9999/");
        this.isact = 2;
        this.sound_time();
        console.log(this.socket1, 111);
        this.socket1.addEventListener("open", (event) => {
          console.log("open");
        });

        this.socket1.addEventListener("message", (event) => {
          let res = JSON.parse(event.data);
          if (res.status == 1 && res.type == "tips" && res.tips.indexOf("获取roomid成功") != -1) {
            this.roomId = res.room_id;
            this.showStatus = true;
            this.$message.success("开始监听直播间");
          }
          if (res.status == 0 && res.tips == "未开播") {
            this.$message.error("检测到该直播间未开播");
          }
          console.log("res;", res);

          if (res.type == "ChatMessage") {
            if (this.form.platform == "sph" && this.monitor && res.content == "来了") {
              this.check_captions(event.data);
            } else {
              if (this.interaction) {
                this.getRecover(res.content);
              }
            }
          } else {
            console.log(111);
            if (this.monitor) {
              this.check_captions(event.data);
            }
          }
          if (res.content) {
            let obj = {
              name: res.name,
              content: res.content,
              time: formatDate(new Date()),
            };
            this.reviewList.push(obj);
            document.getElementById("listBox").scrollIntoView({
              behavior: "smooth",
              block: "end",
              inline: "nearest",
            });
          }
        });
        this.socket1.addEventListener("onerror", (event) => {
          if (this.wsReloadIdx > 3) {
            this.$message.info(`弹幕服务连接失败，请重试`);
            return;
          }
          this.$message.info(`弹幕服务连接失败，正在重连 ${this.wsReloadIdx}/3`);
          this.wsReloadIdx++;

          setTimeout(() => {
            this.socket1 = new WebSocket("ws://127.0.0.1:9999/");
            this.wsReloadIdx = 1;
          }, 1200);
        });
      }, 3000);
    },
    getRecover(question) {
      let params = {
        user_id: localStorage.getItem("token"),
        localhosts: this.$onlyId,
        kb_ids: this.actGoods.zb_id || "KB68461a12c50b40e69c68ba3841a6bfe1",
        question,
      };

      axios.post(localStorage.getItem("fetUrl") + "/api/local_doc_qa/local_doc_chats", params).then((res) => {
        console.log("知识库回答", res.data.response.slice(18, -2));
        let text = res.data.response.slice(18, -2);
        console.log("text", text);
        let data = {
          fileName: randomText6() + ".mp3",
          text,
          type: "back",
          insertType: this.option.insertType,
          ...this.liveInfo.sound,
        };
        this.AudioSynthesis(data);
        this.interaction_time();
      });
    },
    check_captions(data) {
      check_captions({
        data,
        live_id: this.liveInfo.id,
        // goods_id: this.goods_id,
        // type: this.form.platform,
      }).then((res) => {
        if (res.code == "200") {
          let data = {
            fileName: randomText6() + ".mp3",
            text: res.data.value,
            type: "back",
            insertType: this.option.insertType,
            ...this.liveInfo.sound,
          };
          this.AudioSynthesis(data);
          this.sound_time(); //启动间隔引导
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    sound_time() {
      if (this.option.control) {
        this.monitor = false;
        setTimeout(() => {
          this.monitor = true; //发送倒计时结束
        }, this.option.control * 1000);
      }
    },
    interaction_time() {
      if (this.option.interaction) {
        this.interaction = false;
        setTimeout(() => {
          this.interaction = true; //发送倒计时结束
        }, this.option.interaction * 1000);
      }
    },
    changeVolume(e) {
      console.log("changeVolume", e);
      this.sendToFrame({ volume: e / 10 });
    },
    selChange(e) {
      console.log("e", e);
      switch (e) {
        case "sph":
          this.showAddr = false;
          this.form.input = "https://channels.weixin.qq.com/platform/live/home";
          break;
        case "qita":
          this.showAddr = false;
          break;
        default:
          this.showAddr = true;
          break;
      }
    },
    getItem(e, idx) {
      this.activeInfo = e;
      this.index = idx;
    },
  },
};
</script>
<style lang="less" scoped>
::v-deep {
  .el-input__inner {
    border: 0;
  }

  .el-dialog {
    border-radius: 8px;
  }

  .el-dialog__body {
    padding: 0px 30px;
  }
}

.con {
  height: 90vh;
  width: 87vw;
  background: #d6dff9;
  // overflow: scroll;
  display: flex;
  justify-content: space-between;

  .box {
    background: #f5f7fe;
    border-radius: 10px;
    height: 100%;
  }

  .title {
    font-size: 15px;
    color: #547b9c;
    text-align: left;
    font-weight: bold;
  }

  .left {
    width: 28%;
    padding-right: 10px !important;

    .list {
      height: 95%;
      overflow: scroll;
    }

    .goodItem {
      background: #fff;
      height: 160px;
      width: 100%;
      padding: 20px;
      margin-bottom: 20px;
      border-radius: 8px;
      margin-right: 5px;

      img {
        width: 120px;
        height: 120px;
        border-radius: 8px;
        overflow: hidden;
      }

      .info {
        margin-left: 20px;
        text-align: left;
        width: 86%;
        padding: 10px;

        .tit {
          font-size: 16px;
          font-weight: bold;
          margin-bottom: 12px;
        }

        .btn {
          background: #d9edf5;
          padding: 1px 7px;
          color: #4284cf;
          border: 1px solid #4284cf;
          border-radius: 4px;
          margin-right: 6px;
        }

        /deep/.el-input {
          width: 70px;
        }
      }

      .tools {
        position: relative;
        z-index: 99;
        width: 32px;
        height: 32px;
        line-height: 32px;
        background: #fff;
        border-radius: 4px;
        border: 1px solid #c8dfff;
        top: 0px;
        left: 5px;
      }
    }

    .goodItem:last-child {
      margin-bottom: 0;
    }
  }

  .center {
    margin: 0 12px;
    width: 459px;
    overflow: hidden;
    background: transparent;
    border-radius: 10px;

    video,
    iframe,
    img {
      width: 459px;
      height: 816px;
    }

    .bottom {
      height: 75px;
      width: 100%;
      background: #fff;
      display: flex;
      align-items: center;
      justify-content: center;

      .el-slider {
        width: 60%;
      }
    }
  }

  .right {
    width: 27%;

    .box1 {
      border-radius: 10px;
      overflow: hidden;
      color: #547b9c;
      font-size: 15px;
      background: #f4f5fe;
      height: 95%;
    }

    .form {
      padding: 10px 15px 0;
    }

    .top {
      justify-content: space-around;
      padding: 10px;
      background-color: #fff;
    }

    .act {
      color: #0362ee;
    }

    .tit {
      padding: 12px;
    }

    .fitem {
      padding: 0px 15px;
    }

    .box2 {
      text-align: left;
    }

    .reviewBox {
      height: 50vh;
      border-radius: 0 0 10px 10px;
      overflow-y: scroll;

      .litem {
        background: #fff;
        padding: 10px;
        flex-wrap: wrap;
        text-align: left;
        border-bottom: 2px solid #f5f5f5;

        .main {
          width: 80%;
        }
      }
    }

    .recoverBox {
      height: 34vh;
      margin-top: 15px;
      padding: 15px;

      .el-textarea__inner {
        border: 0;
        // color: #bcbcbc;
        background: #f8f9fc;
        padding: 16px 20px !important;
        font-size: 15px !important;
      }
    }
  }
}

/deep/.el-select {
  width: 83% !important;
}

.item {
  margin-right: 25px;
  margin-bottom: 15px;
  background: #fff;
  border-radius: 5px;
  justify-content: space-between;
  height: 280px;
  overflow: hidden;
}

.item .desc {
  padding: 10px;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  background: #f1f1f1;
}

.item img {
  width: 135px;
  height: 240px;
  border-radius: 5px 5px 0 0;
}

.item:last-child,
.item:nth-child(2n) {
  margin-right: 0;
}
</style>
